import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Subscription } from "rxjs";
import { catchError, finalize, first, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiService } from "../service/api.service";
import { CookieService } from 'ngx-cookie-service';

import { AuthModel } from "./models/auth.model";
import { UserModel, MenuModel, menus, access } from "./models/user.model";
import { LangService } from "../service/lang.service";

const API_URL = `${environment.API_URL}`;

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private subscriptions: Subscription[] = [];
    private tokenKey = `${environment.appVersion}-${environment.AUTHDATA_KEY}`;
    private userDataKey = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    private menuDataKey = `${environment.appVersion}-${environment.MENUDATA_KEY}`;
    currentUserSubject: BehaviorSubject<UserModel>;
    user: UserModel;
    menu: MenuModel;
    storageType: number;

    constructor(private apiservice: ApiService, private router: Router, private cookieService: CookieService, private langService: LangService) {
        this.user = new UserModel();
        this.menu = new MenuModel();
        this.currentUserSubject = new BehaviorSubject<UserModel>(this.user);
        this.storageType = environment.STORAGE_TYPE;
    }

    login(formData: any) {
        return this.apiservice.Post(`${API_URL}/login`, formData)
            .pipe(
                map((response: any) => {
                    return response;
                }),
                // catchError(err => {
                //     console.log(err);
                // })
            );
    }

    forgetPassword(formData: any) {
        return this.apiservice.Post(`${API_URL}/reSendOtp`, formData)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    setNewPassword(formData: any) {
        return this.apiservice.Post(`${API_URL}/forgotPassword`, formData)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }

    signUp(formData: any) {
        return this.apiservice.Post(`${API_URL}/signup`, formData)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
    verification(formData: any) {
        return this.apiservice.Post(`${API_URL}/verification`, formData)
            .pipe(
                map((response: any) => {
                    return response;
                })
            );
    }
    getCustomerProfile() {
        return this.apiservice.Get(`${API_URL}/getProfile`)
            .pipe(
                map((response: any) => {
                    return response;
                }),
            );
    }

    logout(errorCode?: number) {
        if (errorCode == 401) {
            this.removeFromStorage(this.tokenKey);
            this.removeFromStorage(this.userDataKey, 2);
            this.removeFromStorage(this.menuDataKey, 2);
            this.removeFromStorage('userProfile', 2);
            this.router.navigate(['/auth/login']);
            return;
        }
        const formData = new FormData()
        this.apiservice.Post(`${API_URL}/logout`, formData)
            .subscribe(value => {
                this.removeFromStorage(this.tokenKey);
                this.removeFromStorage(this.userDataKey, 2);
                this.removeFromStorage(this.menuDataKey, 2);
                this.removeFromStorage('userProfile');
                this.router.navigate(['/auth/login']);
            }, err => {
                if (err.status == 401 || errorCode == 401) {
                    this.removeFromStorage(this.tokenKey);
                    this.removeFromStorage(this.userDataKey, 2);
                    this.removeFromStorage(this.menuDataKey, 2);
                    this.removeFromStorage('userProfile');
                    this.router.navigate(['/auth/login']);
                }
            });
    }

    setAuthToSessionStorage(auth: AuthModel): boolean {
        // store auth authToken/refreshToken/epiresIn in session storage to keep user logged in between page refreshes
        if (auth && auth.authToken) {
            this.setToStorage(this.tokenKey, JSON.stringify(auth), this.storageType);
            return true;
        }
        return false;
    }

    private getAuthFromLocalStorage(): AuthModel {
        let authData = new AuthModel;
        try {
            authData.setAuth(this.getFromStorage(this.tokenKey));
        } catch (error) {
            console.error(error);
        }
        return authData;
    }

    getAuthToken() {
        let currentToken: any = this.getFromStorage(this.tokenKey);
        if (currentToken) {
            let accessToken = JSON.parse(currentToken);
            return accessToken.authToken;
        }
        return;
    }

    setUserDataToStorage(user: any) {
        if (user) {
            this.removeFromStorage(this.userDataKey);
            this.setToStorage(this.userDataKey, JSON.stringify(user), 2);
        }
    }

    setMenuDataToStorage(menu: any) {
        if (menu) {
            this.removeFromStorage(this.menuDataKey, 2);
            // let menus1 = new menus();
            // let access1 = new access();
            // access1.id = '';
            // access1.access_name = 'Edit';
            // menus1.access.push(access1);
            // menus1.id = '';
            // menus1.key = 'Bulk-Update';
            // menus1.name = 'Bulk-Update';
            // menus1.submenu = [];
            // menus1.url = 'user/bulk-update';
            // this.menu.menus.push(menus1);
            this.setToStorage(this.menuDataKey, JSON.stringify(menu), 2);
        }
    }

    private getUserDataFromStorage(): UserModel {
        let userData = new UserModel;
        try {
            if (this.getFromStorage(this.userDataKey, 2) != null) {
                userData.setUser(JSON.parse(this.getFromStorage(this.userDataKey, 2) as string));
            }
        } catch (error) {
            console.error(error);
        }
        return userData;
    }

    private getMenuDataFromStorage(): MenuModel {
        let menuData = new MenuModel;
        try {
            if (this.getFromStorage(this.menuDataKey, 2) != null) {
                menuData.setMenu(JSON.parse(this.getFromStorage(this.menuDataKey, 2) as string));
            }
        } catch (error) {
            console.error(error);
        }
        return menuData;
    }

    setAuthInfo(objResult: any) {
        const auth = new AuthModel();
        auth.setAccessToken(objResult.headers.get('access_token'));
        // auth.setAccessToken(result.headers.get('expires_in'));
        this.setAuthToSessionStorage(auth);
    }

    setUserInfo(objResult: any, web: string) {
        this.user = new UserModel();
        this.user.setUser(objResult.body.data);
        this.setUserDataToStorage(this.user);
        this.menu = new MenuModel();
        this.menu.setMenu(objResult.body.data.menus);
        this.setMenuDataToStorage(objResult.body.data.menus);
        if (this.user) {
            this.currentUserSubject.next(this.user);
            const userLang = (this.user.defaultLanguage) ? this.user.defaultLanguage.toLowerCase() : "en";
            const currentLang = this.langService.getLang();
            if (userLang != '' && userLang != currentLang) {
                this.langService.setLang(userLang);
            }
        } else {
            this.logout();
        }

        if (web != '') {
            this.router.navigate(["product", web]);
        } else {
            this.router.navigate(["/user/card/list"]);
        }
    }

    getUserInfo(): UserModel {
        const sb1 = this.currentUserSubject.pipe()
            .subscribe(user => {
                if (user.id != '') {
                    this.user = Object.assign({}, user);
                } else {
                    this.user = this.getUserDataFromStorage();
                    if (this.user.id != '') {
                        this.currentUserSubject.next(this.user);
                    }
                    const userLang = (this.user.defaultLanguage) ? this.user.defaultLanguage.toLowerCase() : "en";
                    this.langService.setLang(userLang);
                    this.menu = this.getMenuDataFromStorage();
                }
            });
        this.subscriptions.push(sb1);
        return this.user;
    }

    getMenuInfo(): MenuModel {
        if (this.menu) {
            this.menu = Object.assign({}, this.menu);
        } else {
            this.menu = this.getMenuDataFromStorage();
        }
        return this.menu;
    }

    get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserModel) {
        this.currentUserSubject.next(user);
    }

    isLoggedIn(): boolean {
        this.user = this.getUserInfo();
        // Check if the user is currently logged in
        return (this.user.id !== '' && this.user.id !== null);
    }

    isAdmin() {
        return (this.user.userTypeName === 'Admin') ? true : false;
    }

    setProfileInfo(objProfile: any) {
        this.user = this.getUserInfo();
        if (this.user) {
            this.user.firstname = objProfile.firstName;
            this.user.lastname = objProfile.lastName;
            this.user.fullname = objProfile.firstName + ' ' + objProfile.lastName;
            this.user.email = objProfile.email;
            this.user.phone = objProfile.phone;
            this.user.defaultLanguage = objProfile.language;
            this.user.photo = objProfile.profilePhoto;
            this.setUserDataToStorage(this.user);
            this.currentUserSubject.next(this.user);
        }
    }

    setCardCountInfo(objCardCount: any) {
        this.user = this.getUserInfo();
        if (this.user) {
            this.user.totalCards = objCardCount.total_count;
            this.user.pendingCards = objCardCount.pending_count;
            this.setUserDataToStorage(this.user);
            this.currentUserSubject.next(this.user);
        }
    }

    setToStorage(key: string, value: any, storageType: number) {
        switch (storageType) {
            case 1:
                this.cookieService.set(key, value, undefined, '/');
                break;
            case 2:
                localStorage.setItem(key, value);
                break;
            case 3:
                sessionStorage.setItem(key, value);
                break;
        }
    }

    getFromStorage(key: string, storageType?: number) {
        if (storageType == undefined || storageType == null) {
            storageType = this.storageType;
        }
        let value;
        switch (storageType) {
            case 1:
                value = this.cookieService.get(key) ? this.cookieService.get(key) : null;
                break;
            case 2:
                value = localStorage.getItem(key) ? localStorage.getItem(key) : null;
                break;
            case 3:
                value = sessionStorage.getItem(key) ? sessionStorage.getItem(key) : null;
                break;
        }
        return value;
    }

    removeFromStorage(key: string, storageType?: number) {
        if (storageType == undefined || storageType == null) {
            storageType = this.storageType;
        }
        switch (storageType) {
            case 1:
                this.cookieService.delete(key, '/');
                break;
            case 2:
                localStorage.removeItem(key);
                break;
            case 3:
                sessionStorage.removeItem(key);
                break;
        }
    }
}