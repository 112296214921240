import { Component, ElementRef, OnInit, Renderer2, ViewChild,EventEmitter,  Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';
import { LangService } from "src/app/service/lang.service";
import { AuthService } from 'src/app/auth/auth.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from "rxjs";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  styles: [`
    /* Custom CSS rules */
    .ui-selectbutton {
      width: 120px;
      height: 14px;
    }
    .ui-selectbutton .ui-button {
      width: 56px;
      height: 20px;
    }
    .ui-selectbutton .ui-button-text {
      font-size: 12px;
    }
  `]
})
export class HeaderComponent implements OnInit {
  private subscriptions: Subscription;
  @ViewChild('toggleButton') toggleButton!: ElementRef;
  toggleStyle: boolean = false;
  loginButton: boolean = true;
  Lang: any = "";
  langForm: FormGroup;
  stateOptions: any[] = [];
  dir: string = '';
  userName: string = '';
  // burger menu
  showToggle = false;
  bigScreen = false;
  headerLoaded: boolean = false;

  constructor(public translate: TranslateService, public langser: LangService, public router: Router, private authService: AuthService, public fb: FormBuilder,
    private renderer: Renderer2) {
    this.langForm = this.fb.group({
      lang: new FormControl('en')
    });
    this.stateOptions = this.langser.languageList();
    this.translate.use(this.langser.getLang());
    this.subscriptions = this.langser.getData().subscribe(value => {
      this.translate.use(value);
      if (value == 'en') {
        this.dir = 'ltr';
      }
      if (value == 'ar') {
        this.dir = 'rtl';
      }
    });
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.toggleButton && e.target !== this.toggleButton.nativeElement) {
        this.showToggle = false;
      }
    });
  }

  ngOnInit(): void {
    // this.loginButton = this.authService.isLoggedIn();
   
    this.loginButton =(this.authService.isLoggedIn());
    
    if(this.loginButton){
    this.userName = this.authService.getUserInfo().fullname;
    }

    let lang = this.langser.getLang();
    this.setValue(lang);
    this.bigScreen = window.innerWidth > 767;
    window.addEventListener("resize", event => {
      this.bigScreen = window.innerWidth > 767;
    });
  }

  setValue(lang: string) {
    this.translate.use(lang);
    this.langser.setLang(lang);
    this.langForm.patchValue({ lang: lang });
    if (lang == 'en') {
      this.dir = 'ltr';
    }
    if (lang == 'ar') {
      this.dir = 'rtl';
    }
  }

  onToggle() {
    this.showToggle = !this.showToggle;
  }

  changeLang(lang: string) {
    this.translate.use(lang);
    this.Lang = lang;
    console.log(lang);
  }
  dashBoard() {
    this.router.navigate(["/user/card/list"]);
  }
  toggle() {
    this.toggleStyle = !this.toggleStyle;
  }

  Home() {
    this.router.navigate(['../home']);
    document.getElementById("home")?.scrollIntoView({ behavior: "smooth" });
  }

  Product() {
    this.router.navigate(['../home'], { fragment: "cardtype" });
    document.getElementById("cardtype")?.scrollIntoView({ behavior: "smooth" });
  }

  Features() {
    this.router.navigate(['../home'], { fragment: "features" });
    document.getElementById("features")?.scrollIntoView({ behavior: "smooth" });
  }

  Demo() {
    this.router.navigate(['../home'], { fragment: "demo" });
    document.getElementById("demo")?.scrollIntoView({ behavior: "smooth" });
  }

  Contactus() {
    this.router.navigate(['../home'], { fragment: "contactus" });
    document
      .getElementById("contactus")
      ?.scrollIntoView({ behavior: "smooth" });
  }

  cart() {
    this.router.navigate(['../product/cart']);
  }

  login() {
    this.router.navigate(["../../auth/login"]);
  }

  logOut() {
    this.authService.logout();
    this.loginButton = true;
    this.Home();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  
}
