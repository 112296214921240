import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HeaderComponent, FooterComponent, TawkComponent } from './components';
import { AccordionModule } from 'primeng/accordion';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { MenuModule } from 'primeng/menu';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ToastModule } from "primeng/toast";
import { SharedModule } from 'src/app/shared/shared.module';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const COMPONENTS = [HeaderComponent, FooterComponent, TawkComponent]
@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    HttpClientModule,
    AccordionModule,
    ReactiveFormsModule, FormsModule, SelectButtonModule, MenuModule, ToastModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
    }),
    NgxCaptchaModule,
    SharedModule
  ],
  exports: [...COMPONENTS, AccordionModule, TranslateModule, MenuModule, NgxCaptchaModule, ToastModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedWebModule { }
