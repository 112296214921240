import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProductComponent } from './web/product/product.component';

const routes: Routes = [
  {
    path: "home",
    loadChildren: () =>
      import("./web/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "product",
    loadChildren: () =>
      import("./web/product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
  },
   { path: ':id', loadChildren: () => import('./web/card/card.module').then(m => m.CardModule) },
  { path: 'product/:slug', component: ProductComponent },
  { path: "", redirectTo: "home", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
