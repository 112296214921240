import { Component,  OnInit, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/service/lang.service';
import { Subscription } from "rxjs";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ToastComponent } from 'src/app/shared/components';
import { WebService } from 'src/app/web/web.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  providers: [MessageService],
})
export class FooterComponent implements OnInit {
  @ViewChild(ToastComponent) toastComp!: ToastComponent;
  @ViewChild('captchaElem') captchaElem!: ReCaptcha2Component;
  @ViewChild('captchaElem', { static: false }) captchaComponent!: ReCaptcha2Component;
  private subscriptions: Subscription;
  dir: string = '';
  enquiryForm: FormGroup;
  siteKey: string = environment.CAPTCHA_KEY;
  mailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  currentYear: number = 0;
  showCaptcha: boolean = false;
  myStaticCaptchaId: any;
  isBrowser: boolean = false;
  
  constructor(public translate: TranslateService, public langser: LangService, public router: Router, 
    public fb: FormBuilder, public webService: WebService, @Inject(PLATFORM_ID) private platformId: Object) {
    this.subscriptions = this.langser.getData().subscribe(value => {
      this.setValue(value);
    });
    this.enquiryForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(this.mailRegex)]),
      mobile: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
      recaptcha: new FormControl('', [Validators.required]),
    });
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    const lang = this.langser.getLang();
    this.setValue(lang);
    this.currentYear = new Date().getFullYear()
  }

  setValue(lang: string) {
    this.translate.use(lang);
    if (lang == 'en') {
      this.dir = 'ltr';
    }
    if (lang == 'ar') {
      this.dir = 'rtl';
    }
  }

  Home() {
    this.router.navigate(['../home']);
    document.getElementById("home")?.scrollIntoView({ behavior: "smooth" });
  }

  Product() {
    this.router.navigate(['../home'], { fragment: "cardtype" });
    document.getElementById("cardtype")?.scrollIntoView({ behavior: "smooth" });
  }

  Features() {
    this.router.navigate(['../home'], { fragment: "features" });
    document.getElementById("features")?.scrollIntoView({ behavior: "smooth" });
  }

  Demo() {
    this.router.navigate(['../home'], { fragment: "demo" });
    document.getElementById("demo")?.scrollIntoView({ behavior: "smooth" });
  }

  Contactus() {
    this.router.navigate(['../home'], { fragment: "contactus" });
    document
      .getElementById("contactus")
      ?.scrollIntoView({ behavior: "smooth" });
  }

  sendDetails() {
    const formData = new FormData;
    formData.append('name', this.enquiryForm.value.name);
    formData.append('companyName', this.enquiryForm.value.companyName);
    formData.append('city', this.enquiryForm.value.city);
    formData.append('email', this.enquiryForm.value.email);
    formData.append('mobile', this.enquiryForm.value.mobile);
    formData.append('message', this.enquiryForm.value.message);

    this.webService.enquiryForm(formData).subscribe((result: any) => {
      if (result.status == 200) {
        console.log('success');
        this.toastComp.displayToastMessage("success", "Success", result.body.success.message);
        this.enquiryFormReset();
      }
      else if (result.error.code == 203) {
        this.toastComp.displayToastMessage("error", "Error", this.translate.instant("COMMON.ERRORMSG.MANDATORYFIELDS"));
      }
      else {
        this.toastComp.displayToastMessage("error", "Error", this.translate.instant("COMMON.ERRORMSG.MANDATORYFIELDS"));
      }
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  enquiryFormReset() {
    this.enquiryForm.patchValue({ 'name': "" });
    this.enquiryForm.patchValue({ 'companyName': "" });
    this.enquiryForm.patchValue({ 'city': "" });
    this.enquiryForm.patchValue({ 'email': "" });
    this.enquiryForm.patchValue({ 'mobile': "" });
    this.enquiryForm.patchValue({ 'message': "" });
    this.captchaElem.resetCaptcha();
  }

  fm() {
    return this.enquiryForm.controls;
  }

  initCaptcha(): void {
    if (this.captchaComponent) {
      this.captchaComponent.reset;
    }
  }
}
