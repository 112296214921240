import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mytag';

  constructor(private translate: TranslateService,private ngxLoader: NgxUiLoaderService) {
    
  }

  async ngOnInit(): Promise<void> {
    this.ngxLoader.start();
    await this.translate.use('en').toPromise();
   
    const appRoot = document.querySelector('app-root') as HTMLElement;
    if (appRoot) {
      appRoot.style.display = 'block';
      this.ngxLoader.stop();
    }
  }
}
